import React, { useState, useMemo } from 'react';
import {
  Switch, Route, withRouter, Redirect,
} from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Error404 from '../views/Elements/Error404/Error404';
import Login from '../views/Elements/Login/Login';
import routesList from './routesList';
import { StyleSection } from '../styles/Common';
import Layout from '../hoc/Layout';
import Preloader from '../views/Elements/Preloader/Preloader';
import ErrorModal from '../views/Elements/Modal/ErrorModal';
import { useToggle } from '../hooks/useToggle';

const Routes = () => {
  const token = localStorage.getItem('token');
  const userRoles = localStorage.getItem('permissions') || [];
  const [isOpen, toggleOpen] = useToggle(false);
  const [isError, setIsError] = useToggle(false);
  const [errorText, setErrorText] = useState('');

  const showError = (text) => {
    setIsError();
    setErrorText(text);
  };

  const renderRoutes = useMemo(() => {
    const currentRoutes = routesList.map((route) => {
      const kek = route.permissions.filter(itemm => userRoles.indexOf(itemm) !== -1);
      if (kek.length) {
        return (
          <Route
            key={route.name}
            path={route.name}
            exact
            render={rest => (
              <route.value
                {...rest}
                preloader={toggleOpen}
                showError={showError}
                userRoles={userRoles}
              />
            )}
          />
        );
      }
      return null;
    });
    return currentRoutes;
  }, [token]);

  return (
    <Layout>
      <Route
        render={({ location }) => (
          token && userRoles !== null && location.pathname !== '/' ? (
            <TransitionGroup className="transitionGroup">
              <CSSTransition key={location.key} timeout={{ enter: 500, exit: 500 }} classNames="fade">
                <StyleSection>
                  <Switch location={location}>
                    <Route
                      path="/login/"
                      exact
                      render={rest => (
                        <Login
                          {...rest}
                          preloader={toggleOpen}
                          showError={showError}
                        />
                      )}
                    />
                    {renderRoutes}
                    <Route component={Error404} />
                  </Switch>
                </StyleSection>
              </CSSTransition>
            </TransitionGroup>
          )
            : token && userRoles !== null && location.pathname === '/'
              ? (
                <Switch location={location}>
                  <Route
                    render={() => <Redirect to={{ pathname: '/orders/' }} />}
                  />
                </Switch>
              ) : (
                <Switch location={location}>
                  {location.pathname !== '/login/' && (
                    <Route
                      render={() => <Redirect to={{ pathname: '/login/' }} />}
                    />
                  )}
                  <Route
                    path="/login/"
                    exact
                    render={rest => (
                      <Login
                        {...rest}
                        preloader={toggleOpen}
                        showError={showError}
                      />
                    )}
                  />
                </Switch>
              )
        )}
      />
      <Preloader isOpen={isOpen} />
      <ErrorModal
        isError={isError}
        errorText={errorText}
        setIsError={setIsError}
        showError={showError}
      />
    </Layout>
  );
};

export default withRouter(Routes);
