import styled from 'styled-components';
import { Link } from 'react-router-dom';
import c from './Colors';

export const NavMenuBox = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  align-self: flex-start;
`;

export const NavSubLinkBox = styled.li`
  text-decoration: ${props => (props.activeLink ? 'underline' : 'none')};
  transition: .3s;
   &:hover{
    text-decoration: underline;
  }
`;

export const NavSubLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 18px 20px 9px;
  font-size: 14px;
`;

export const NavLinkBox = styled.li`
  position: relative;
  background: ${props => (props.activeMenu || props.hover ? c.sub : 'transparent')};
  transition: .3s;
  display: flex;
  height: 100%;
  svg{
    margin-left: 5px;
    transition: .3s;
    transform: ${props => (props.activeMenu ? 'rotate(90deg)' : 'none')};
  }
  /* &:before{
    opacity: ${props => (props.activeMenu || props.hover ? 1 : 0)};
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 2px;
    background: ${c.white};
    transition: .3s;
  } */
`;

export const NavLink = styled(Link)`
  display: flex;
  color: #fff;
  padding: 0px 24px;
  text-decoration: none;
  align-items: center;
`;

export const NavIcon = styled.div`
  display:flex;
  padding-right: 8px;
`;

export const HideBtn = styled.button`
  position: absolute;
  border: 0;
  background: rgba(0,0,0, .4);
  width: 100%;
  color: #fff;
  padding: 10px;
  font-size: 20px;
  display: flex;
  justify-content:flex-end;
  outline: 0;
  bottom: 0;
  z-index: 9;
`;

export const NavSubBox = styled.div`
   visibility: ${props => (props.activeMenu || props.isHover ? 'visible' : 'hidden')};
   opacity: ${props => (props.activeMenu || props.isHover ? 1 : 0)};
   display: flex;
   position: absolute;
   background: ${c.sub};
   width: 100%;
   transition: .5s;
   left: 0;
   z-index: ${props => (props.isHover ? 9 : 0)};
`;
