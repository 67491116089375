import React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import Portal from './PortalPreloader';
import { PreloaderBox } from '../../../styles/Preloader';

const duration = 300;

const ModalWithTransitionStyles = styled(Portal)`
  &.modal-transition-enter {
    opacity: 0.01;
  }
  &.modal-transition-enter-active {
    transition: ${duration}ms;
    opacity: 1;
  }
  &.modal-transition-exit {
    opacity: 1;
  }
  &.modal-transition-exit-active {
    opacity: 0.01;
    transition: ${duration}ms;
  }
`;

const Preloader = ({ isOpen, small }) => (
  <React.Fragment>
    <CSSTransition
      in={isOpen}
      className="modal-transition"
      classNames="modal-transition"
      unmountOnExit
      timeout={duration}
    >
      {small ? <PreloaderBox>ЗАГРУЗКА....</PreloaderBox> : <ModalWithTransitionStyles />}
    </CSSTransition>
  </React.Fragment>
);

export default Preloader;
