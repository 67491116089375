import styled from 'styled-components';
import c from './Colors';

export const HeaderBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  background-color: ${c.main};
  justify-content: space-between;
`;

export const HeaderLogoBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 6%;
  height: 100%;
  background: ${c.sub};
`;

export const HeaderLogo = styled.img`
  max-width: 45px;
  width: 100%;
  height: auto;
`;

export const Userbox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 34%; */
  white-space: nowrap;
  background: ${c.sub};
  height: 100%;
`;

export const VersionText = styled.div`
  position: absolute;
  bottom: 5px;
  display: flex;
  color: ${c.white};
  font-size: 14px;
`;
