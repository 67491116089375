import React, { useState, useEffect } from 'react';
import { Btn, BtnsBox } from '../../../../styles/Modal';
import { BtnAdd } from '../../../../styles/Common';
import Modal from '../../../Elements/Modal/Modal';
import request from '../../../../utils/request';
import RequestForm from './RequestForm/RequestForm';

const NewOrder = ({
  orderList, toggleOpen, isOpen, initialData, setOrderData, setOrderUpdate, orderData, fetchOrders, showError, preloader, orderUpdate, userRoles, orderView,
}) => {
  const [isValid, setIsValid] = useState(false);
  const [status, setStatus] = useState('');

  const resetModal = () => {
    setOrderData({ ...initialData });
    toggleOpen();
    setOrderUpdate(false);
    setIsValid(false);
  };

  useEffect(() => {
    const { visitors, receiverName, startDate } = orderData;
    const recsurname = receiverName.split(' ')[0] || '';
    const recname = receiverName.split(' ')[1] || '';
    if (recsurname && recname && startDate && visitors.every(item => item.name && item.surname)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [orderData]);

  const addOrder = async () => {
    const userId = window.localStorage.getItem('userId');
    const newPassData = { ...orderData, createdById: userId };

    // if (!newPassData.carNumber) {
    //   delete newPassData.carNumber;
    // }
    if (!newPassData.endDate) {
      newPassData.endDate = newPassData.startDate;
    }
    if (isValid) {
      try {
        preloader();
        await request('/api/orders/', 'post', newPassData);
      } catch (e) {
        preloader();
        showError('не удалось добавить заявку');
      }
      fetchOrders();
      preloader();
      resetModal();
    }
  };

  const updateOrder = async () => {
    if (isValid) {
      const itemId = orderData.id;
      delete orderData.id;
      preloader();
      try {
        await request(`/api/orders/${itemId}/`, 'patch', { ...orderData });
      } catch (e) {
        showError(e.data.error);
      }
      preloader();
      fetchOrders();
      resetModal();
    }
  };
  return (
    <React.Fragment>
      <BtnAdd onClick={toggleOpen} tip={orderList.data.length}>
        Добавить
      </BtnAdd>
      <Modal title={orderUpdate ? 'Редактирование заявки' : orderView ? 'Заявка' : 'Добавление Заявки'} isOpen={isOpen} toggleOpen={resetModal} maxWidth="1200px">
        <RequestForm
          addOrder={addOrder}
          orderData={orderData}
          setOrderData={setOrderData}
          status={status}
          setStatus={setStatus}
          userRoles={userRoles}
          orderView={orderView}
        />
        {!orderView
        && (
        <BtnsBox>
          <Btn
            margin="0 25px 0 0"
            bg={isValid ? 'green' : 'grey'}
            onClick={orderUpdate ? updateOrder : addOrder}
          >
            Сохранить
          </Btn>
          <Btn onClick={resetModal} bg="#FA494A">Отменить</Btn>
        </BtnsBox>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default React.memo(NewOrder);
