import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {
  ModalBackground, ModalBox, ModalHeader, ModalTitle, ModalBody, ModalContentBox, BtnClose,
} from '../../../styles/Modal';
import { ReactComponent as CloseIcon } from '../../../images/close.svg';


const Portal = ({
  onClose, title, children, className,
}) => {
  const modalMarkup = (
    <ModalBackground className={className} onMouseDown={onClose}>
      <ModalBox onMouseDown={e => e.stopPropagation()}>
        <ModalContentBox>
          <ModalHeader>
            <BtnClose onClick={onClose}><CloseIcon fill="red" /></BtnClose>
            <ModalTitle>{title}</ModalTitle>
          </ModalHeader>
          <ModalBody>
            {children}
          </ModalBody>
        </ModalContentBox>
      </ModalBox>
    </ModalBackground>
  );

  return ReactDOM.createPortal(modalMarkup, document.body);
};

Portal.propTypes = {
  /**
   * Child nodes
   */
  children: PropTypes.node.isRequired,
  /**
   * Close handler for modal button
   */
  onClose: PropTypes.func,
  /**
   * Title text to show in header
   */
  title: PropTypes.string.isRequired,
};

Portal.defaultProps = {
  onClose: null,
};

export default Portal;
