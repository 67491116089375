import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Title } from '../../../styles/Common';
import {
  AboutBox, AboutItem, AboutItemTitle, AboutItemInfo,
} from '../../../styles/About';
import request from '../../../utils/request';

const About = () => {
  const [info, setInfo] = useState({});
  const [time, setTime] = useState({});
  const [intervalBox, setIntervalBox] = useState();

  const timer = (data) => {
    const { eqServerLoadedOn } = data;
    const currentTime = moment();
    const durat = moment.duration(currentTime.diff(moment(eqServerLoadedOn)));
    const days = durat.get('days');
    const hours = durat.get('hours');
    const min = durat.get('minutes');
    const sec = durat.get('seconds');
    setTime({
      days, hours, min, sec,
    });
  };

  const fetchInfo = async () => {
    const res = await request('/server/public/getVersions/', 'post', {});
    setIntervalBox(setInterval(() => timer(res.data.data), 1000));
    setInfo({ ...res.data.data });
  };

  useEffect(() =>
    // fetchInfo();
    () => { clearInterval(intervalBox); },
  []);

  const {
    baqServerDbVersion, dbLastUpgradedOn, version_tvrp, version_cl,
  } = info;
  const {
    days, hours, min, sec,
  } = time;
  return (
    <React.Fragment>
      <Title>О системе</Title>
      <AboutBox>
        <AboutItem>
          <AboutItemTitle>
Версия базы данных:
          </AboutItemTitle>
          <AboutItemInfo>1.0.0</AboutItemInfo>
        </AboutItem>

        <AboutItem>
          <AboutItemTitle>
Версия сервера:
          </AboutItemTitle>
          <AboutItemInfo>1.0.1</AboutItemInfo>
        </AboutItem>

        <AboutItem>
          <AboutItemTitle>
Версия админ. панели:
          </AboutItemTitle>
          <AboutItemInfo>0.9.3</AboutItemInfo>
        </AboutItem>

        <AboutItem>
          <AboutItemTitle>
Последнее обновление БД:
          </AboutItemTitle>
          <AboutItemInfo>{moment(dbLastUpgradedOn).format('HH:mm:ss - DD.MM.YYYY')}</AboutItemInfo>

        </AboutItem>
        {/*
        <AboutItem>
          <AboutItemTitle>
Время работы базы данных:
          </AboutItemTitle>
          <AboutItemInfo>
            {days ? `${days} дн.` : ''}
            {' '}
            {hours ? `${hours} час.` : ''}
            {' '}
            {min ? `${min} мин.` : '0 мин.'}
            {' '}
            {sec && `${sec} сек.`}
          </AboutItemInfo>
        </AboutItem> */}
      </AboutBox>
    </React.Fragment>
  );
};

export default About;
