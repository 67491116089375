import React from 'react';
import DatePicker from 'react-datepicker';
import 'moment/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ruDate from 'date-fns/locale/ru';
import { isAfter } from 'date-fns';
import { IoMdCalendar } from 'react-icons/io';
import {
  RadioCheck, RadioInput, RadioLabel, InputLabel, StatusBox, CheckLabel, CheckInput, Check, ReceiverLabel,
  StatusRadio, ContractorBox, Input, DateBox, CalendarBox, FormBox, FullnameBox, CalendarContainer, DateLabel, CalendarTitle, FormItem,
} from '../../../../../styles/requestForm';
import Visitors from './Visitors/Visitors';

const RequestForm = ({
  orderData, setOrderData, userRoles, orderView,
}) => {
  const fullname = window.localStorage.getItem('fullname');
  const {
    comment, carNumber, recipientType, receiverPlace, receiverName, startDate, endDate, companyName, contractor,
  } = orderData;

  const recsurname = receiverName.split(' ')[0] || '';
  const recname = receiverName.split(' ')[1] || '';
  const recpatr = receiverName.split(' ')[2] || '';

  console.log('orderData = ', orderData);
  console.log('name = ', recname);
  console.log('surname = ', recsurname);
  console.log('patr = ', recpatr);

  const handleInput = (e) => {
    const { name, value } = e.currentTarget;
    setOrderData({ ...orderData, [name]: value });
  };

  const handleContractor = (evt) => {
    evt.stopPropagation();
    const newContractor = !contractor;
    setOrderData({ ...orderData, contractor: newContractor });
  };

  const handleReceiver = (e) => {
    const { name, value } = e.currentTarget;
    let recFullname = '';
    if (name === 'recSurname') {
      recFullname = `${value} ${recname} ${recpatr}`;
    }
    if (name === 'recName') {
      recFullname = `${recsurname} ${value} ${recpatr}`;
    }
    if (name === 'recPatr') {
      recFullname = `${recsurname} ${recname} ${value}`;
    }
    setOrderData({ ...orderData, receiverName: recFullname });
  };

  const dateChange = ({ startD, endD }) => {
    let start = null;
    let end = null;
    start = startD || startDate;
    end = endD || endDate;
    end = isAfter(start, end) ? start : end;
    setOrderData({ ...orderData, startDate: start, endDate: end });
  };

  const dpConfig = {
    selectsStart: true,
    showTimeSelect: false,
    className: 'dateInput',
    dateFormat: 'dd.MM.yyyy',
    placeholderText: 'Выберите дату',
    locale: ruDate,
  };

  let convertStart = startDate;
  let convertEnd = endDate;
  if (startDate) {
    convertStart = moment(startDate).toDate();
  }
  if (endDate) {
    convertEnd = moment(endDate).toDate();
  }

  return (
    <React.Fragment>
      <FormBox>
        <FormItem>
          <InputLabel>
            <Input name="fullname" value={fullname} readOnly type="text" />
          </InputLabel>
          <StatusBox>
            <StatusRadio>
              <RadioLabel>
              Физ.лиц
                <RadioInput
                  type="radio"
                  name="radio"
                  onChange={() => setOrderData({ ...orderData, recipientType: 0 })}
                  checked={recipientType === 0}
                  disabled={orderView}
                />
                <RadioCheck />
              </RadioLabel>
              <RadioLabel>
              Юр.лиц
                <RadioInput
                  type="radio"
                  name="radio"
                  onChange={() => setOrderData({ ...orderData, recipientType: 1 })}
                  checked={recipientType === 1}
                  disabled={orderView}
                />
                <RadioCheck />
              </RadioLabel>
              <RadioLabel>
                Работник
                <RadioInput
                  type="radio"
                  name="radio"
                  onChange={() => setOrderData({ ...orderData, recipientType: 2 })}
                  checked={recipientType === 2}
                  bg="Gold"
                  disabled={orderView}
                />
                <RadioCheck />
              </RadioLabel>

              {recipientType === 1
            && (
              <ContractorBox>
                <InputLabel>
                  <Input type="text" value={companyName || ''} onChange={handleInput} name="companyName" required placeholder="Компания" readOnly={orderView} />
                </InputLabel>
                <CheckLabel onClick={handleContractor}>
                  <span>
                    Подрядчик
                  </span>
                  <CheckInput
                    type="checkbox"
                    onClick={e => e.stopPropagation()}
                    defaultChecked={contractor}
                  />
                  <Check />
                </CheckLabel>
              </ContractorBox>
            )}
            </StatusRadio>
          </StatusBox>
        </FormItem>
        <FormItem bg="#EDF1F6">
          <Visitors orderData={orderData} setOrderData={setOrderData} orderView={orderView} />
        </FormItem>
        <FormItem>
          <ReceiverLabel>
            <span>
              Данные приглашающей стороны:
            </span>
            <FullnameBox mw="22%">
              <Input onChange={handleReceiver} name="recSurname" value={recsurname || ''} required type="text" placeholder="Фамилия" readOnly={orderView} />
              <Input onChange={handleReceiver} name="recName" value={recname || ''} required type="text" placeholder="Имя" readOnly={orderView} />
              <Input onChange={handleReceiver} name="recPatr" value={recpatr || ''} type="text" placeholder="Отчество" readOnly={orderView} />
              <input value={receiverPlace || ''} onChange={handleInput} name="receiverPlace" type="text" placeholder="№ кабинета" readOnly={orderView} />
            </FullnameBox>
          </ReceiverLabel>
        </FormItem>
        <FormItem bg="#EDF1F6" mg="0 0 20px">
          <DateBox>
            <span style={{ fontSize: 20, margin: '0 0 20px' }}>
            Дата действия пропуска:
            </span>
            <CalendarContainer>
              <CalendarBox>
                <CalendarTitle>Дата начала:</CalendarTitle>
                <DateLabel border="red">
                  <DatePicker
                    {...dpConfig}
                    selected={convertStart}
                    selectsStart
                    startDate={convertStart}
                    endDate={convertEnd}
                    onChange={date => dateChange({ startD: date })}
                    readOnly={orderView}
                  />
                  <IoMdCalendar size="28" color="#5682A3" />
                </DateLabel>
              </CalendarBox>
              <CalendarBox>
                <CalendarTitle>Дата окончания:</CalendarTitle>
                <DateLabel>
                  <DatePicker
                    {...dpConfig}
                    selected={convertEnd}
                    selectsEnd
                    startDate={convertStart}
                    endDate={convertEnd}
                    onChange={date => dateChange({ endD: date })}
                    readOnly={orderView}
                  />
                  <IoMdCalendar size="28" color="#5682A3" />
                </DateLabel>
              </CalendarBox>
            </CalendarContainer>
          </DateBox>
        </FormItem>
        <FormItem>
          <InputLabel>
            <input value={comment || ''} onChange={handleInput} type="text" name="comment" placeholder="Комментарий" readOnly={orderView} />
          </InputLabel>
        </FormItem>
        {userRoles.indexOf('orderSuperCreate') !== -1
          && (
          <FormItem bg="#EDF1F6">
            <InputLabel>
              <span>
          Гос. номер автомобиля Посетителя:
              </span>
              <Input bg="lightyellow" type="text" value={carNumber || ''} onChange={handleInput} name="carNumber" placeholder="Номер" readOnly={orderView} />
            </InputLabel>
          </FormItem>
          )
        }


      </FormBox>
    </React.Fragment>
  );
};

export default RequestForm;
