import React, { useState, useEffect } from 'react';
import axios from 'axios';
import useReactRouter from 'use-react-router';
import {
  LoginBox, LoginForm, LoginText, Btn,
} from '../../../styles/LoginStyles';
import Input from '../Input/Input';

const Login = ({ showError }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const { history } = useReactRouter();

  useEffect(() => {
    if (window.localStorage.token) {
      history.push('/orders/');
    }
  }, [role]);

  useEffect(() => {
    window.localStorage.clear();
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    const userInfo = {
      username,
      password,
    };
    if (username.trim() && password.trim()) {
      axios({
        method: 'post',
        url: '/api/login',
        data: userInfo,
      })
        .then((res) => {
          const { data } = res;
          if (res.data.error) {
            showError(res.data.error);
          } else {
            window.localStorage.setItem('token', data.token);
            window.localStorage.setItem('fullname', data.fullname);
            window.localStorage.setItem('userId', data.userId);
            window.localStorage.setItem('rolesName', data.rolesName);
            window.localStorage.setItem('permissions', data.permissions);
            setRole(data.username);
          }
        });
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    const lowerValue = value.toLowerCase();
    if (name === 'username') {
      setUsername(lowerValue);
    } else {
      setPassword(value);
    }
  };

  const items = [
    {
      label: 'Логин:', name: 'username', value: username || '',
    },
    {
      label: 'Пароль:', name: 'password', type: 'secure', value: password || '',
    },
  ];

  const renderInputs = () => items.map(item => (
    <Input
      key={item.name}
      item={item}
      onChange={handleInput}
    />
  ));
  return (
    <LoginBox>
      <LoginForm>
        <LoginText>Вход в систему управления:</LoginText>
        {renderInputs()}
        <Btn
          bg={username && password ? 'main' : 'gray'}
          type="submit"
          onClick={handleLogin}
        >
          Войти
        </Btn>
      </LoginForm>
    </LoginBox>
  );
};

export default Login;
