import React, { useState, useEffect } from 'react';
import Table from '../../Elements/Table/Table';
import DeleteWarning from '../../Elements/Modal/DeleteWarning';
import Config from './TableConfig';
import { EmptyList } from '../../../styles/Common';
import request from '../../../utils/request';
import { useToggle } from '../../../hooks/useToggle';

const OrdersTable = ({
  orderList, userRoles, preloader, showError, fetchOrders, setOrderUpdate, setOrderData, toggleOpen,
}) => {
  const [showDelWarn, setShowDelWarn] = useToggle(false);
  const [orderId, setOrderId] = useState(false);
  const [comment, setComment] = useState('');

  let isMount = true;

  useEffect(() => {
    if (isMount) fetchOrders();
    return () => {
      isMount = false;
    };
  }, []);

  const delOrder = async () => {
    preloader();
    try {
      await request(`/api/orders/delete/${orderId}`, 'patch', { comment });
    } catch (e) {
      console.log('e = ', e); // проверить!
      showError('не получилось удалить');
    }
    fetchOrders();
    setShowDelWarn();
    setOrderId(false);
    preloader();
  };

  const blockOrder = async (rowId, blockId) => {
    preloader();
    try {
      await request(`/api/orders/block/${rowId}`, 'patch');
      fetchOrders();
    } catch (e) {
      showError('не получилось заблокировать');
    }
    preloader();
  };

  const handleTableIcon = async (e, row) => {
    const { dataset } = e.target;
    const {
      orderId: id,
      blockById,
    } = row;

    if (dataset.name === 'del') {
      setOrderId(id);
      setShowDelWarn();
    } else if (dataset.name === 'block') {
      blockOrder(id, blockById);
    } else if (dataset.name === 'edit') {
      setOrderUpdate(true);
      const res = await request(`/api/orders/${id}`);
      setOrderData(res.data.response);
      toggleOpen();
    }
  };

  const Setting = Config(userRoles);
  const { columns } = Setting;

  return (
    <React.Fragment>
      {userRoles.indexOf('orderViewAll') !== -1 || userRoles.indexOf('orderViewSelf') !== -1 ? orderList.isFetching ? <EmptyList>Загрузка пропусков</EmptyList>
        : orderList.data.length ? (
          <Table
            data={orderList.data}
            columns={columns}
            keys="uid"
            defaultSorted={[{
              dataField: 'createdAt',
              order: 'desc',
            }]}
            handleIcon={handleTableIcon}
          />
        ) : <EmptyList>Пропусков нет</EmptyList> : null}
      <DeleteWarning
        comment={comment}
        setComment={setComment}
        confirmDel={delOrder}
        isOpen={showDelWarn}
        toggleOpen={setShowDelWarn}
      />
    </React.Fragment>
  );
};

export default React.memo(OrdersTable);
