import React from 'react';
import Modal from './Modal';
import {
  BtnsBox, Btn, ErrorText,
} from '../../../styles/Modal';

const ErrorModal = ({ isError, errorText, showError }) => {
  let typeText = false;
  if (typeof errorText === 'string') {
    typeText = true;
  }
  const {
    code, comment, text, errorMessage,
  } = errorText;

  return (
    <Modal title={text ? text.rus : ''} isOpen={isError} toggleOpen={() => showError('')}>
      <ErrorText>{text && text.eng}</ErrorText>
      <ErrorText>{comment && comment}</ErrorText>
      <ErrorText>{code && `КОД ${code}`}</ErrorText>
      <ErrorText>{typeText && errorText}</ErrorText>
      <ErrorText>{errorMessage && errorMessage.ru}</ErrorText>
      <BtnsBox>
        <Btn onClick={() => showError('')}>ОК</Btn>
      </BtnsBox>
    </Modal>
  );
};
export default ErrorModal;
