import moment from 'moment';

const styles = {
  background: {
    log: '#111',
    info: '#111',
    warn: '#111',
    error: '#111',
  },
  color: {
    log: '#25dd53',
    info: '#07b5f9',
    warn: '#fcde46',
    error: '#F00',
  },
  symbol: {
    log: String.fromCodePoint(0x1F984),
    info: String.fromCodePoint(0x1F440),
    warn: String.fromCodePoint(0x1F981),
    error: String.fromCodePoint(0x1F479),
  },
};

const prepareLogData = (...args) => {
  const logString = [];
  args.forEach((v) => {
    if (typeof v === 'object') {
      logString.push(JSON.stringify(v));
    } else {
      logString.push(v);
    }
  });
  return logString;
};

const sendLogData = (from = 'ControlPanel', type = 'info', data) => {
  // to-do Send log to faq-server
  const dataObject = {
    from,
    type,
    data,
  };
  return dataObject;
};

const createLogger = (isProduction = false) => {
  const { background, color, symbol } = styles;
  const date = moment().format('DD.MM.YYYY HH:mm:ss');

  if (!isProduction) {
    window.console = {
      log: window.console.log.bind(window.console, '%c%s', `background: ${background.log}; color: ${color.log}`, `${symbol.log}${date} [LOG]${symbol.log}`),
      error: window.console.log.bind(window.console, '%c%s', `background: ${background.error}; color: ${color.error}`, `${symbol.error}${date} [ERROR]${symbol.error}`),
      info: window.console.log.bind(window.console, '%c%s', `background: ${background.info}; color: ${color.info}`, `${symbol.info}${date} [INFO]${symbol.info}`),
      warn: () => null,
      dir: window.console.dir,
      // warn: window.console.log.bind(window.console, '%c%s', `background: ${background.warn}; color: ${color.warn}`, `${symbol.warn}${date} [WARN]${symbol.warn}`),
    };
  } else {
    window.console = {
      log: (...args) => sendLogData('ControlPanel', 'log', prepareLogData(...args)),
      error: (...args) => sendLogData('ControlPanel', 'error', prepareLogData(...args)),
      info: (...args) => sendLogData('ControlPanel', 'info', prepareLogData(...args)),
      warn: (...args) => sendLogData('ControlPanel', 'warn', prepareLogData(...args)),
    };
  }
};

export default createLogger;
