import React, { useState, useEffect } from 'react';
import {
  CheckLabel, List, ToggleList, LabelText, CheckBox, InputCheck, Check,
} from '../../../styles/Common';
import { useToggle } from '../../../hooks/useToggle';

const CheckBoxList = ({ item, data, setData }) => {
  const { name, options, checked } = item;
  const [check, setCheck] = useState([...checked]);
  const [openList, setOpenList] = useToggle(false);


  const showList = () => {
    setOpenList();
  };


  useEffect(() => {
    setCheck([...checked]);
  }, [checked]);

  const handleCheck = (e) => {
    const numValue = e.target.value;
    let checkArr = [];
    let newValues = [];
    checkArr = [...check];

    const checkIndex = checkArr.indexOf(numValue);
    if (checkIndex === -1) {
      newValues = [...checkArr, numValue];
      setCheck([...newValues]);

      setData({ ...data, [name]: newValues });
    } else {
      checkArr.splice(checkIndex, 1);
      setData({ ...data, [name]: checkArr });

      setCheck([...checkArr]);
    }
  };

  const checkAll = () => {
    const allOptions = options.map(optionItem => optionItem.key);
    if (check.length === allOptions.length) {
      setCheck([]);
      setData({ ...data, [name]: [] });
    } else {
      setCheck([...allOptions]);
      setData({ ...data, [name]: allOptions });
    }
  };

  return (
    <React.Fragment>
      <ToggleList onClick={showList}>
        {openList ? 'Закрыть список' : 'Раскрыть список'}
      </ToggleList>
      {openList
     && (
     <React.Fragment>
       <ToggleList onClick={checkAll}>Отметить все</ToggleList>
       <List>
         { options && options.map(option => (
           <CheckLabel key={option.id}>
             <LabelText>{option.name}</LabelText>
             <CheckBox>
               <InputCheck
                 type="checkbox"
                 value={option.key}
                 onChange={handleCheck}
                 checked={check.some(checkItem => (
                   checkItem === option.key))}
               />
               <Check />
             </CheckBox>
           </CheckLabel>
         ))}
       </List>
     </React.Fragment>
     )}
    </React.Fragment>
  );
};

export default CheckBoxList;
