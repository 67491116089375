import React from 'react';
import NavMenu from './NavMenu/NavMenusss';
import Logout from '../Login/Logout';
import {
  HeaderBox, HeaderLogo, Userbox, HeaderLogoBox,
} from '../../../styles/HeaderStyles';
import logo from '../../../images/logo.png';

const Header = ({ userRole, username, permissions }) => (
  <HeaderBox>
    <HeaderLogoBox>
      <div><HeaderLogo src={logo} /></div>
    </HeaderLogoBox>
    <NavMenu permissions={permissions} />
    <Userbox>
      <Logout username={username} userRole={userRole} />
    </Userbox>
  </HeaderBox>
);

export default React.memo(Header);
