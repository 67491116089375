import React, { useState, useEffect } from 'react';
import Input from '../../../Elements/Input/Input';
import RenderCheckList from './RenderCheckList';


const RenderInputs = ({
  userData, setUserData, setIsValid, userUpdate, roles,
}) => {
  const [pwd, setPwd] = useState('');
  const [pwd2, setPwd2] = useState('');

  useEffect(() => {
    const { username } = userData;
    if (userUpdate && username && pwd === pwd2) {
      setIsValid(true);
    }
  }, []);

  const handleInput = (e) => {
    const { name, value, type } = e.target;
    const lowerValue = value.toLowerCase();
    let validData = { ...userData };
    let repeatPwd = pwd2;
    let currentPwd = pwd;
    if (name === 'pwd2') {
      repeatPwd = value;
      setPwd2(value);
    } else if (name === 'pwd') {
      currentPwd = value;
      setPwd(value);
      setUserData({ ...validData, password: value });
    } else {
      if (type === 'checkbox') {
        validData = { ...userData, [name]: !JSON.parse(value) }; // convert string to boolean
      } else if (name === 'fullname') {
        validData = { ...userData, [name]: value };
      } else {
        validData = { ...userData, [name]: lowerValue };
      }
      setUserData({ ...validData });
    }
    const { username, fullname, email } = validData;
    if (username && fullname && email && currentPwd && currentPwd === repeatPwd) { // valid for add user
      setIsValid(true);
    } else if (userUpdate && username && currentPwd === repeatPwd) { // valid for update user
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const { username, email, fullname } = userData;
  const items = [
    { label: 'Логин:', name: 'username', value: username || '' },
    {
      label: 'Пароль:', name: 'pwd', value: pwd || '', type: 'secure',
    },
    {
      label: 'Повторите пароль:', name: 'pwd2', value: pwd2 || '', type: 'secure',
    },
    { label: 'Email:', name: 'email', value: email || '' },
    { label: 'ФИО:', name: 'fullname', value: fullname || '' },
  ];
  return (
    <React.Fragment>
      {items.map(item => (
        <Input
          key={item.name}
          item={item}
          onChange={handleInput}
        />
      ))}
      <RenderCheckList
        setUserData={setUserData}
        userData={userData}
        userUpdate={userUpdate}
        roles={roles}
      />
    </React.Fragment>

  );
};
export default RenderInputs;
