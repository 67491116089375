import styled from 'styled-components';

export const FormBox = styled.div`
  max-width: 1280px;
  width: 100%;
  border: 1px solid #ccc;
`;

export const FormItem = styled.div`
  padding: 20px;
  background: ${props => props.bg || '#fff'};
  margin: ${props => props.mg || 0};
`;

export const StatusBox = styled.div`
  width: 100%;
  position: relative;
`;

export const StatusRadio = styled.div`
  margin: 0 0 50px;
  display: flex;
  justify-content: space-between;
`;

export const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 26px;
`;


export const RadioCheck = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
  &:after {
    content: "";
    position: absolute;
    display: none;
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
`;


export const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  ~ ${RadioCheck}{
    background-color: #ccc;
  }
  &:checked ~ ${RadioCheck}{
    background-color: ${props => props.bg || '#5682A3'};
  }
`;

export const InputLabel = styled.label`
  display: flex;
  flex-direction: column;
  margin: 0 0 24px;
  span{
    margin: 0 0 8px;
  }
  input{
    width: 100%;
    height: 38px;
    padding: 6px;
  }
`;

export const VisitorLabel = styled(InputLabel)`
  input{
    margin: 0 0 8px;
  }
`;

export const ReceiverLabel = styled(InputLabel)`
  margin: 0;
`;

export const Check = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  &:after{
    content: "";
    position: absolute;
    display: none;
  }
`;

export const CheckLabel = styled.label`
  display: flex;
  align-items: center;
  height: 25px;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover input ~ ${Check} {
    background-color: #ccc;
  };
  input:checked ~ ${Check} {
    background-color: #5682A3;
  };
  ${Check}:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const CheckInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  
  &:checked ~ ${Check}:after {
    display: block;
  }
`;

export const ContractorBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: -50px;
  max-width: 360px;
  width: 100%;
  label{ 
    margin: 0 10px 0 0;
  }
`;

export const InviteBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const DateBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 20px;
`;

export const CalendarBox = styled.div`
  display: flex;
  align-items: center;
  max-width: 380px;
  width: 100%;
  margin: 0 0 8px;
`;

export const FullnameBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  text-align: center;
  margin: 0 0 10px;
  input{
    max-width: ${props => props.mw || '100%'};
  }
`;

export const CalendarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  input{
    height: 38px;
    padding: 8px;
    width: 100%;
  }
`;

export const Input = styled.input`
  border: ${props => (props.required ? '1px solid red' : '1px solid #aaa')};
  background: ${props => props.bg || 'transparent'};
`;

export const VisitorBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

export const AddBtn = styled.button`
  background: #5682A3;
  color: #fff;
  font-size: 28px;
  border: 0;
  padding: 6px 12px;
  cursor: pointer;
`;

export const DelVisitor = styled.button`
  font-size: 22px;
  height: 38px;
  cursor: pointer;
  background: red;
  opacity: .7;
  border: 0;
  color: #fff;
  padding: 0px 12px;
`;

export const DateLabel = styled.label`
  display: flex;
  align-items: center;
  input{
    border: 1px solid ${props => props.border || 'inital'};
  }
`;

export const CalendarTitle = styled.p`
  margin: 0 10px 0 0;
`;
