import React from 'react';
import moment from 'moment';
import { textFilter } from 'react-bootstrap-table2-filter';
import { BtnTable } from '../../../styles/Common';
import { Carret } from '../../../styles/Table';
import { ReactComponent as True } from '../../../images/true.svg';
import { ReactComponent as False } from '../../../images/false.svg';
import { ReactComponent as Edit } from '../../../images/edit.svg';
import { ReactComponent as Trash } from '../../../images/del.svg';
import { ReactComponent as Block } from '../../../images/block.svg';
import { ReactComponent as Unblock } from '../../../images/unblock.svg';
import formatters from '../../../utils/tableFormatters';

const isVisit = (value, { endDate, startDate }) => (
  <div className="filterBoxTable">
    { moment().isBefore(endDate) && !moment().isBefore(startDate) ? <BtnTable bg="main"><True fill="white" /></BtnTable>
      : <BtnTable bg="red"><False fill="white" /></BtnTable> }
  </div>
);


export default function (userRoles) {
  function iconFormatter(cell, { blockById }) {
    return (
      <React.Fragment>
        {userRoles.indexOf('orderUpdate') !== -1 && <BtnTable data-name="edit" bg="main"><Edit fill="white" /></BtnTable>}
        {userRoles.indexOf('orderBlock') !== -1
          ? blockById ? <BtnTable data-name="block" bg="main"><Block fill="white" /></BtnTable> : <BtnTable data-name="block" bg="gray"><Unblock fill="white" /></BtnTable> : null}
        {userRoles.indexOf('orderDelete') !== -1 && <BtnTable data-name="del" bg="red"><Trash fill="white" /></BtnTable>}
      </React.Fragment>
    );
  }

  return {
    columns: [
      {
        dataField: 'uid',
        text: '№ заявки',
        sort: true,
        sortCaret: order => (
          <React.Fragment>
            {order ? order === 'asc' ? <Carret deg="-90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret>}
          </React.Fragment>
        ),
        editable: false,
        headerStyle: {
          width: 110,
        },
      },
      {
        dataField: 'receiverName',
        text: 'ФИО пригласившего',
        sort: true,
        sortCaret: order => (
          <React.Fragment>
            {order ? order === 'asc' ? <Carret deg="-90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret>}
          </React.Fragment>
        ),
        headerFormatter: formatters.placeholder,
        editable: false,
        filter: textFilter({
          className: 'customPlaceholder',
          placeholder: 'ФИО пригласившего',
        }),
      },
      {
        dataField: 'visitorName',
        text: 'ФИО посетителя',
        sort: true,
        sortCaret: order => (
          <React.Fragment>
            {order ? order === 'asc' ? <Carret deg="-90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret>}
          </React.Fragment>
        ),
        headerFormatter: formatters.placeholder,
        editable: false,
        filter: textFilter({
          className: 'customPlaceholder',
          placeholder: 'ФИО посетителя',
        }),
      },
      {
        dataField: 'startDate',
        text: 'Дата начала',
        sort: true,
        sortCaret: order => (
          <React.Fragment>
            {order ? order === 'asc' ? <Carret deg="-90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret>}
          </React.Fragment>
        ),
        editable: false,
        formatter: formatters.dateFormat,
        headerStyle: {
          width: 130,
        },
      },
      {
        dataField: 'endDate',
        text: 'Дата окончания',
        sort: true,
        sortCaret: order => (
          <React.Fragment>
            {order ? order === 'asc' ? <Carret deg="-90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret>}
          </React.Fragment>
        ),
        editable: false,
        formatter: formatters.dateFormat,
        headerStyle: {
          width: 155,
        },
      },
      {
        dataField: '',
        isDummyField: true,
        text: 'Действителен',
        sort: true,
        sortCaret: order => (
          <React.Fragment>
            {order ? order === 'asc' ? <Carret deg="-90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret>}
          </React.Fragment>
        ),
        formatter: isVisit,
        editable: false,
        headerStyle: {
          width: 140,
        },
      },
      {
        dataField: 'createdAt',
        text: 'Дата создания',
        sort: true,
        sortCaret: order => (
          <React.Fragment>
            {order ? order === 'asc' ? <Carret deg="-90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret>}
          </React.Fragment>
        ),
        editable: false,
        formatter: formatters.dateTimeFormat,
        headerStyle: {
          width: 145,
        },
      },
      {
        dataField: 'Settings',
        text: '',
        isDummyField: true,
        headerStyle: {
          width: 173,
        },
        formatter: iconFormatter,
        formatExtraData: {},
        editable: false,
      },
    ],
  };
}
