
export default [
  {
    text: 'Мониторинг',
    link: '/monitoring/',
    subItems: [
    ],
    permissons: ['monitoringViewAll'],
  },
  {
    text: 'Заявки',
    link: '/orders/',
    subItems: [
    ],
    permissons: ['orderViewAll', 'orderViewSelf'],
  },
  {
    text: 'Пользователи',
    link: '/users/',
    subItems: [
    ],
    permissons: ['userViewAll'],
  },
  {
    text: 'Настройки',
    link: '/about/',
    subItems: [
    ],
    permissons: ['settingViewAll'],
  },
];
