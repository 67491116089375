import React from 'react';
import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';
import img from '../../../images/logo.png';

const PortalPreloader = () => {
  const PreloaderAnimation = keyframes`
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }`;
  const LogoAnim = keyframes`
  0% {
    transform: scale(1)
  }
  10% {
    transform: scale(1.05)
  }
  20% {
    transform: scale(1)
  }
  30% {
    transform: scale(1.05)
  }
  40% {
    transform: scale(1)
  }
  100% {
    transform: scale(1)
  }`;

  const Wrapper = styled.div`
position: fixed;
background-color: rgba(0,0,0,.4);
top: 0;
display: flex;
align-items: center;
justify-content: center;
left: 0;
width: 100%;
height: 100%;
z-index: 99999;
`;
  const Logo = styled.div`
width: 100px;
height: 100px;
background-size: 100px;
background-repeat: no-repeat;
position: relative;
top: -133px;
animation-name: ${LogoAnim};
animation-duration: 2s;
animation-timing-function: linear;
animation-iteration-count: infinite;
//margin-left: -45px;
background-image: url(${img});
`;
  const Loader = styled.div`
display: block;
position: relative;
left: 68px;
width: 145px;
height: 145px;
margin: 150px 0px 0 -100px;
border-radius: 50%;
border: 10px solid transparent;
border-top-color: rgba(245, 128, 32, 0.2);
animation-name: ${PreloaderAnimation};
animation-duration: 2s;
animation-timing-function: linear;
animation-iteration-count: infinite;
&:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 50%;
  border: 10px solid transparent;
  border-top-color: rgba(245, 128, 32, 0.4);
  animation-name: ${PreloaderAnimation};
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
&:after {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  border-radius: 50%;
  border: 10px solid transparent;
  border-top-color: rgba(245, 128, 32, 0.6);
  animation-name: ${PreloaderAnimation};
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}`;
  const Body = styled.div``;
  const modalMarkup = (
    <Wrapper>
      <Body>
        <Loader />
        <Logo />
      </Body>
      {/* <div className={text}>Конфигурационный файл отсутствует.<br/> Создайте его в Админ. панели</div> */}
    </Wrapper>
  );

  return ReactDOM.createPortal(modalMarkup, document.body);
};

export default PortalPreloader;
