import React, { useState, useEffect } from 'react';
import { Btn, BtnsBox } from '../../../../styles/Modal';
import { BtnAdd, Link } from '../../../../styles/Common';
import Modal from '../../../Elements/Modal/Modal';
import request from '../../../../utils/request';
import RenderInputs from './RenderInputs';

const NewUser = ({
  isOpen, toggleOpen, fetchUsers, userUpdate, setUserData,
  userData, initialData, setUserUpdate, preloader, showError,
}) => {
  const [isValid, setIsValid] = useState(false);
  const [roles, setRoles] = useState([]);

  const fetchRoles = async () => {
    const res = await request('/api/roles/', 'get');
    setRoles(res.data.response);
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const resetModal = () => {
    setUserData({ ...initialData });
    toggleOpen();
    setUserUpdate(false);
    setIsValid(false);
  };

  const addUser = async () => {
    if (isValid) {
      await request('/api/users/', 'post', { ...userData })
        .catch((err) => { showError(err.data.error); });
      fetchUsers();
      resetModal();
    }
  };

  const updateUser = async () => {
    if (isValid) {
      const itemId = userData.id;
      delete userData.id;
      if (!userData.password) {
        delete userData.password;
      }
      preloader();
      try {
        await request(`/api/users/${itemId}/`, 'patch', { ...userData });
      } catch (e) {
        console.log(e);
        showError(e.data.error.errorMessage.ru); // ошибка
      }
      preloader();
      fetchUsers();
      resetModal();
    }
  };

  return (
    <React.Fragment>
      <div style={{ display: 'flex' }}>
        <BtnAdd onClick={toggleOpen}>
        Добавить
        </BtnAdd>
        <Link href="/api/users/excel" mg="0 0 0 10px">Скачать в excel</Link>
      </div>


      <Modal title={userUpdate ? 'Редактирование пользователя' : 'Добавление Пользователя'} isOpen={isOpen} toggleOpen={resetModal}>
        <RenderInputs
          userData={userData}
          setUserData={setUserData}
          setIsValid={setIsValid}
          preloader={preloader}
          userUpdate={userUpdate}
          roles={roles}
        />
        <BtnsBox>
          <Btn
            margin="0 25px 0 0"
            bg={isValid ? 'green' : 'grey'}
            onClick={userUpdate ? updateUser : addUser}
          >
            Сохранить
          </Btn>
          <Btn onClick={resetModal} bg="red">Отменить</Btn>
        </BtnsBox>
      </Modal>
    </React.Fragment>
  );
};

export default React.memo(NewUser);
