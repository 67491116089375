import React, { useState, useEffect } from 'react';
import { Tabs } from 'react-web-tools';
import request from '../../../utils/request';
import OrdersTable from './OrdersTable';
import { ContentBox, PreloaderBox } from '../../../styles/Common';
import NewOrder from './NewOrder/NewOrder';
import { useToggle } from '../../../hooks/useToggle';
import OrdersArchive from './OrdersArchive/OrdersArchive';

const Orders = ({
  preloader, showError, userRoles, history,
}) => {
  const userName = localStorage.getItem('fullname');
  const initialData = {
    // createdById: 1,
    startDate: '',
    endDate: '',
    receiverName: userName,
    receiverPlace: '',
    recipientType: 0,
    comment: '',
    carNumber: '',
    visitors: [{
      id: 'testid', name: '', surname: '', patronymic: '',
    }],
    companyName: '',
    contractor: false,
  };
  const [orderList, setOrderList] = useState({ data: [], isFetching: false });
  const [orderData, setOrderData] = useState(initialData);
  const [orderUpdate, setOrderUpdate] = useState(false);
  const [orderView, setOrderOview] = useState(false);
  const [isOpen, toggleOpen] = useToggle(false);
  let isMount = true;

  const fetchOrders = async () => {
    try {
      preloader();
      if (isMount) setOrderList({ data: [], isFetching: true });
      const res = await request('/api/orders/');
      if (isMount) setOrderList({ data: res.data.response, isFetching: false });
      preloader();
    } catch (err) {
      preloader();
      if (err.response.data.status === 403) {
        localStorage.clear();
        showError('У вас недостаточно прав');
      } else {
        if (isMount) setOrderList({ data: [], isFetching: false });
        if (err.response.data.error === 'Unauthorized') {
          history.push('/login');
        }
        showError('Ошибка получения заявок от сервера');
      }
    }
  };



  return (
    <React.Fragment>
      <ContentBox>
        {userRoles.indexOf('orderCreate') !== -1
        && (
          <NewOrder
            orderList={orderList}
            isOpen={isOpen}
            toggleOpen={toggleOpen}
            preloader={preloader}
            showError={showError}
            setOrderUpdate={setOrderUpdate}
            orderUpdate={orderUpdate}
            setOrderData={setOrderData}
            orderData={orderData}
            initialData={initialData}
            fetchOrders={fetchOrders}
            userRoles={userRoles}
            orderView={orderView}
          />
        )}
        <Tabs>
          <div name="Заявки" style={{ width: '100%' }}>
            <PreloaderBox>
              <OrdersTable
                orderList={orderList}
                toggleOpen={toggleOpen}
                userRoles={userRoles}
                preloader={preloader}
                showError={showError}
                fetchOrders={fetchOrders}
                setOrderData={setOrderData}
                setOrderUpdate={setOrderUpdate}
              />
            </PreloaderBox>
          </div>
          {userRoles.indexOf('orderViewAll') !== -1
            && (
              <div name="Архив заявок" style={{ width: '100%' }}>
                <OrdersArchive
                  setOrderData={setOrderData}
                  toggleOpen={toggleOpen}
                  setOrderOview={setOrderOview}
                  preloader={preloader}
                  showError={showError}
                />
              </div>
            )}
        </Tabs>

      </ContentBox>
    </React.Fragment>
  );
};

export default Orders;
