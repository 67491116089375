import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { GlobalBox, MainConent } from '../styles/Common';
import Header from '../views/Elements/Header/Header';
import request from '../utils/request';

const Layout = ({ location, history, children }) => {
  const handlePing = () => setInterval(async () => {
    try {
      await request('/api/checkToken');
    } catch (err) {
      if (!err.response.data.success) {
        console.log('clear wrong token');
        history.push('/login/');
      }
    }
  }, 10000);

  useEffect(() => {
    let ping;
    if (!window.localStorage.token && location.pathname !== '/login/') {
      history.push('/login/');
    }
    if (localStorage.token && location.pathname !== '/login/') {
      ping = handlePing();
    }
    return () => {
      clearInterval(ping);
    };
  }, [localStorage.token]);

  const username = window.localStorage.getItem('fullname');
  const userRole = window.localStorage.getItem('rolesName');
  const permissions = window.localStorage.getItem('permissions') || [];

  return (
    <GlobalBox role={window.localStorage.token}>
      <Header username={username} userRole={userRole} permissions={permissions} />
      <MainConent>
        {children}
      </MainConent>
    </GlobalBox>
  );
};

export default withRouter(Layout);
