import React, { useState, useEffect } from 'react';
import Table from '../../../Elements/Table/Table';
import Config from './TableConfig';
import { EmptyList } from '../../../../styles/Common';
import request from '../../../../utils/request';

const OrdersArchive = ({
  setOrderData, toggleOpen, setOrderOview, showError, preloader,
}) => {
  const [archiveList, setArchiveList] = useState({ data: [], isFetching: false });

  const fetchArchiveList = async () => {
    preloader();
    try {
      setArchiveList({ data: [], isFetching: true });
      const res = await request('/api/orders/archive/');
      setArchiveList({ data: res.data.response, isFetching: false });
    } catch (err) {
      showError('ошибка получения архива');
    }
    preloader();
  };

  useEffect(() => {
    fetchArchiveList();
  }, []);

  const handleTableIcon = async (e, row) => {
    const { dataset } = e.target;
    console.log('row', row);
    const {
      orderId: id,
    } = row;

    if (dataset.name === 'view') {
      const res = await request(`/api/orders/${id}`);
      console.log('res', res);
      setOrderData(res.data.response);
      toggleOpen();
      setOrderOview(true);
    }
  };

  const Setting = Config();
  const { columns } = Setting;

  return (
    <React.Fragment>
      {archiveList.isFetching ? <EmptyList>Загрузка архива пропусков</EmptyList>
        : archiveList.data.length ? (
          <Table
            data={archiveList.data}
            columns={columns}
            keys="uid"
            defaultSorted={[{
              dataField: 'createdAt',
              order: 'desc',
            }]}
            handleIcon={handleTableIcon}
          />
        ) : <EmptyList>Архив пуст</EmptyList>}
    </React.Fragment>
  );
};

export default React.memo(OrdersArchive);
