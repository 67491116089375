import React from 'react';
import Modal from './Modal';
import {
  BtnsBox, Btn, Comment, CommentText,
} from '../../../styles/Modal';

const DeleteWarning = ({
  confirmDel, toggleOpen, isOpen, comment, setComment,
}) => (
  <Modal title="Удалить?" isOpen={isOpen} toggleOpen={toggleOpen}>
    {comment !== undefined
    && (
    <>
      <CommentText>Введите комментарий:</CommentText>
      <Comment value={comment} rows={3} onChange={e => setComment(e.target.value)} />
    </>
    )
    }
    <BtnsBox>
      {comment !== undefined
        ? <Btn onClick={comment && confirmDel} bg={comment ? 'red' : 'gray'} margin="0 25px 0 0">Удалить</Btn>
        : <Btn onClick={confirmDel} bg="red" margin="0 25px 0 0">Удалить</Btn>
      }
      <Btn onClick={toggleOpen}>Отмена</Btn>
    </BtnsBox>
  </Modal>
);

export default DeleteWarning;
