import styled from 'styled-components/macro';
import c from './Colors';
import addArr from '../images/dblArr.png';

export const GlobalBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: ${c.bg};
  overflow: ${props => (props.role ? 'auto' : 'hidden')};
`;

export const ContentBox = styled.div`
  background: ${c.white};
  border: 1px solid #ccc;
  padding: 20px;
`;

export const MainConent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  padding: 35px 20px 20px;
  background: #E7EBF0;
  height: auto;
`;

export const BtnTable = styled.button`
  position: relative;
  width: 35px;
  height: 35px;
  border: 0;
  background: ${props => c[props.bg]};
  padding: 7px;
  margin-right: 10px;
  cursor: pointer;
  svg {
    width: 100%;
    height: auto;
    pointer-events: none
  }
  &:last-child{
    margin-right: 0;
  }
`;

export const Image = styled.img`
  max-width: 100%;
`;


export const BtnAdd = styled.button`
  position: relative;
  max-width: 200px;
  padding: 8px 16px;
  background: ${c.main};
  border: 0;
  display: ${props => (props.flex ? 'flex' : '')}; 
  color: ${c.white};
  font-size: 16px;
  margin: ${props => (props.flex ? '0px' : props.mg || '0 0 25px')};
  outline: 0;
  cursor: pointer;
  &:after{
    position: absolute;
    content: '';
    top: 2px;
    background: url(${addArr});
    right: -35px;
    color: ${c.main};
    opacity: ${props => (props.tip === 0 ? 1 : 0)};
    transform: ${props => (props.tip === 0 ? 'translateX(0%)' : 'translateX(-100%) rotate(0.5turn)')};
    transition: .4s;
    width: 30px;
    height: 30px;
    outline: 0;
  }
`;

export const Link = styled.a`
  display: flex;
  max-width: 200px;
  padding: 8px 15px;
  height: 34px;
  background: ${c.main};
  border: 0;
  display: ${props => (props.flex ? 'flex' : '')}; 
  color: ${c.white};
  font-size: 16px;
  margin: ${props => (props.flex ? '0px' : props.mg || '0 0 25px')};
  outline: 0;
  text-decoration: none;
  cursor: pointer;
`;

export const BigBtnAdd = styled(BtnAdd)`
  max-width: 220px;
  &:after{
    content: none;
  }
`;

export const ReportButtopnBox = styled.div`
  margin-top: 15px;
  button:not(:first-child){
    margin-left: 10px;
  }
`;


export const Title = styled.h1`
  background: ${c.main};
  text-transform: uppercase;
  font-size: 24px;
  text-align: center;
  color: ${c.white};
  padding: 8px 16px;
  margin-bottom: 25px;
`;

export const InputBox = styled.div`
  margin-bottom: 16px;
`;

export const Label = styled.p`
  margin-bottom: 4px;
  font-size: 14px;
`;

export const Input = styled.input`
  border: 1px solid #c2cfd6;
  padding: 0px 12px;
  margin-bottom: 20px;
  width: 100%;
  height: 30px;
  -webkit-text-security: ${props => (props.secure ? 'disc' : 'initial')};
`;

Input.displayName = 'input';

export const Select = styled.select`
  
`;

export const StyleSection = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  margin-bottom: 20px;
`;

export const Empty = styled.div`
  font-size: 28px;
  text-align: center;
`;

export const PreloaderBox = styled.div`
  position: relative;
  width: 100%;
  min-height: ${props => (props.height ? props.height : '150px')};
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 200px;
  overflow-y: scroll;
`;

export const CheckLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
  padding: 6px;
  width: 100%;
  font-size: 16px;
  border: 1px solid ${c.main};
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ToggleList = styled.button`
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  margin-left: 6px;
  color: ${c.main};
  text-decoration:underline;
`;

export const CheckContainer = styled.div`
`;

export const LabelText = styled.p`
  
`;

export const CheckBox = styled.label`
  position: relative;
  display: inline-block;
  width: 52px;
  height: 26px;
  input{
    opacity: 0;
    width: 0;
    height: 0;
  }
`;

export const Check = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  &:before{
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
`;

export const InputCheck = styled.input`
  &:checked + ${Check}{
    background-color: ${c.main};
  }
  &:focus + ${Check}{
    box-shadow: 0 0 1px ${c.main};
  }
  &:checked + ${Check}:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
`;

export const EmptyList = styled.div`
  font-size: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
