import React from 'react';
import VisitorInput from './VisitorInput';
import randomId from '../../../../../../utils/randomId';
import {
  VisitorLabel, VisitorBox, AddBtn,
} from '../../../../../../styles/requestForm';

const Visitors = ({ orderData, setOrderData, orderView }) => {
  const deleteVisitor = (id) => {
    const newVisitorArr = orderData.visitors.filter(item => item.id !== id);
    setOrderData({ ...orderData, visitors: newVisitorArr });
  };

  const handleInput = (e, id) => {
    const newVisitorArr = orderData.visitors.map((item) => {
      if (item.id === id) {
        const { name, value } = e.target;
        const newVisitor = { ...item, [name]: value };
        return newVisitor;
      }
      return item;
    });
    setOrderData({ ...orderData, visitors: newVisitorArr });
  };

  const renderVisitors = () => orderData.visitors.map(item => (
    <VisitorInput
      key={item.id}
      visitor={item}
      deleteVisitor={deleteVisitor}
      handleInput={handleInput}
      orderView={orderView}
    />
  ));

  const addVisitor = () => {
    const newId = randomId(5);
    const newVisitor = {
      id: newId,
      name: '',
      surname: '',
      patronymic: '',
    };
    setOrderData({ ...orderData, visitors: [...orderData.visitors, newVisitor] });
  };


  return (
    <VisitorLabel>
      <span>
   Заполните данные посетителя:
      </span>
      <VisitorBox>
        {renderVisitors()}
        {!orderView && <AddBtn onClick={addVisitor}>+</AddBtn>}
      </VisitorBox>

    </VisitorLabel>
  );
};

export default Visitors;
