
// STATISTICS
import Monitoring from '../views/Statistics/Monitoring/Monitoring';

// CONTROL
import Users from '../views/Control/Users/Users';
import Orders from '../views/Control/Orders/Orders';

// SETTINGS
import About from '../views/Settings/About/About';

export default [
  { name: '/monitoring/', value: Monitoring, permissions: ['monitoringViewAll'] },
  { name: '/users/', value: Users, permissions: ['userViewAll', 'userViewSelf'] },
  { name: '/orders/', value: Orders, permissions: ['orderViewAll', 'orderViewSelf'] },
  { name: '/about/', value: About, permissions: ['settingViewAll'] },
];
