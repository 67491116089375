import React from 'react';
import CheckBoxList from '../../../Elements/Input/CheckBoxList';


const RenderCheckList = ({
  userData, setUserData, roles,
}) => {
  const items = [
    {
      label: 'Роли:', name: 'roles', options: roles, checked: userData.roles,
    },
  ];

  console.log('userData', userData);

  return (
    items.map(item => (
      <div key={item.name}>
        {item.label}
        <CheckBoxList
          item={item}
          data={userData}
          setData={setUserData}
        />
      </div>
    ))
  );
};
export default RenderCheckList;
