import React, { useState } from 'react';
import Config from './TableConfig';
import { EmptyList } from '../../../styles/Common';
import DeleteWarning from '../../Elements/Modal/DeleteWarning';
import request from '../../../utils/request';
import { useToggle } from '../../../hooks/useToggle';
import Table from '../../Elements/Table/Table';

const UsersTable = ({
  userList, preloader, fetchUsers, setUserUpdate, setUserData, toggleOpen, showError, userRoles,
}) => {
  const [showDelWarn, setShowDelWarn] = useToggle(false);
  const [userId, setUserId] = useState(false);

  const delUser = async () => {
    preloader();
    try {
      await request(`/api/users/delete/${userId}`, 'patch');
    } catch (e) {
      showError(e.data.error.errorMessage.ru);
    }
    fetchUsers();
    setShowDelWarn();
    setUserId(false);
    preloader();
  };

  const blockUser = async (rowId) => {
    preloader();
    try {
      await request(`/api/users/block/${rowId}`, 'patch');
      fetchUsers();
    } catch (e) {
      showError(`${e.data.error.errorMessage.ru}`);
    }
    preloader();
  };

  const handleTableIcon = (e, row) => {
    const { dataset } = e.target;
    const {
      id, username,
      email,
      fullname,
      roles,
    } = row;

    if (dataset.name === 'del') {
      setUserId(id);
      setShowDelWarn();
    } else if (dataset.name === 'block') {
      blockUser(id);
    } else if (dataset.name === 'edit') {
      const rolesKeys = roles.map(item => item.key);
      setUserUpdate(true);
      setUserData({
        id,
        username,
        password: '',
        email,
        fullname,
        roles: rolesKeys,
      });
      toggleOpen();
    }
  };

  const Setting = Config(userRoles);
  const { columns } = Setting;
  return (
    <React.Fragment>
      {userRoles.indexOf('orderViewAll') !== -1 ? userList.isFetching ? <EmptyList>Загрузка пользователей</EmptyList>
        : userList.data.length ? (
          <Table
            data={userList.data}
            columns={columns}
            keys="id"
            defaultSorted={[{
              dataField: 'createdAt',
              order: 'asc',
            }]}
            handleIcon={handleTableIcon}
          />
        ) : <EmptyList>Пользователей нет</EmptyList> : null}
      <DeleteWarning confirmDel={delUser} isOpen={showDelWarn} toggleOpen={setShowDelWarn} />
    </React.Fragment>
  );
};

export default React.memo(UsersTable);
