import styled from 'styled-components';
import c from './Colors';

export const AboutBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const AboutItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 14px 25px;
  background: ${c.white};
  margin: 0 25px 25px;
  max-width: 300px;
  width: 100%;
  height: 100px;
  max-height: 100px;
`;

export const AboutItemTitle = styled.h3`
  font-size: 16px;
  margin-bottom: 10px;
  color: ${c.main};
`;

export const AboutItemInfo = styled.div`
  
`;
