import React from 'react';


const Monitoring = () => (
  <React.Fragment>
     Мониторинг в разработке
  </React.Fragment>
);

export default Monitoring;
