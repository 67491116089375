import React from 'react';
import { textFilter } from 'react-bootstrap-table2-filter';
import { BtnTable } from '../../../../styles/Common';
import { Carret } from '../../../../styles/Table';
import { ReactComponent as View } from '../../../../images/eye.svg';
import formatters from '../../../../utils/tableFormatters';

export default function () {
  function iconFormatter() {
    return (
      <React.Fragment>
        <BtnTable data-name="view" bg="main"><View fill="white" /></BtnTable>
      </React.Fragment>
    );
  }

  return {
    columns: [
      {
        dataField: 'uid',
        text: '№ заявки',
        sort: true,
        sortCaret: order => (
          <React.Fragment>
            {order ? order === 'asc' ? <Carret deg="-90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret>}
          </React.Fragment>
        ),
        editable: false,
        headerStyle: {
          width: 110,
        },
      },
      {
        dataField: 'receiverName',
        text: 'ФИО пригласившего',
        sort: true,
        sortCaret: order => (
          <React.Fragment>
            {order ? order === 'asc' ? <Carret deg="-90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret>}
          </React.Fragment>
        ),
        headerFormatter: formatters.placeholder,
        editable: false,
        filter: textFilter({
          className: 'customPlaceholder',
          placeholder: 'ФИО пригласившего',
        }),
      },
      {
        dataField: 'visitorName',
        text: 'ФИО посетителя',
        sort: true,
        sortCaret: order => (
          <React.Fragment>
            {order ? order === 'asc' ? <Carret deg="-90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret>}
          </React.Fragment>
        ),
        headerFormatter: formatters.placeholder,
        editable: false,
        filter: textFilter({
          className: 'customPlaceholder',
          placeholder: 'ФИО посетителя',
        }),
      },
      {
        dataField: 'startDate',
        text: 'Дата начала',
        sort: true,
        sortCaret: order => (
          <React.Fragment>
            {order ? order === 'asc' ? <Carret deg="-90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret>}
          </React.Fragment>
        ),
        editable: false,
        formatter: formatters.dateFormat,
        headerStyle: {
          width: 130,
        },
      },
      {
        dataField: 'endDate',
        text: 'Дата окончания',
        sort: true,
        sortCaret: order => (
          <React.Fragment>
            {order ? order === 'asc' ? <Carret deg="-90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret>}
          </React.Fragment>
        ),
        editable: false,
        formatter: formatters.dateFormat,
        headerStyle: {
          width: 155,
        },
      },
      {
        dataField: 'createdAt',
        text: 'Дата создания',
        sort: true,
        sortCaret: order => (
          <React.Fragment>
            {order ? order === 'asc' ? <Carret deg="-90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret>}
          </React.Fragment>
        ),
        editable: false,
        formatter: formatters.dateTimeFormat,
        headerStyle: {
          width: 145,
        },
      },
      {
        dataField: 'Settings',
        text: '',
        isDummyField: true,
        headerStyle: {
          width: 173,
        },
        formatter: iconFormatter,
        formatExtraData: {},
        editable: false,
      },
    ],
  };
}
