import React from 'react';
import { textFilter } from 'react-bootstrap-table2-filter';
import formatter from '../../../utils/tableFormatters';
import { BtnTable } from '../../../styles/Common';
import { Carret } from '../../../styles/Table';
import { ReactComponent as Edit } from '../../../images/edit.svg';
import { ReactComponent as Trash } from '../../../images/del.svg';
import { ReactComponent as Block } from '../../../images/block.svg';
import { ReactComponent as Unblock } from '../../../images/unblock.svg';

export default function Setting(userRoles) {
  function iconFormatter(cell, { blockById }) {
    return (
      <React.Fragment>
        {userRoles.indexOf('userUpdate') !== -1 && <BtnTable data-name="edit" bg="main"><Edit fill="white" /></BtnTable>}
        {userRoles.indexOf('userBlock') !== -1
        && blockById ? <BtnTable data-name="block" bg="main"><Block fill="white" /></BtnTable> : <BtnTable data-name="block" bg="gray"><Unblock fill="white" /></BtnTable>}
        {userRoles.indexOf('userDelete') !== -1 && <BtnTable data-name="del" bg="red"><Trash fill="white" /></BtnTable>}
      </React.Fragment>
    );
  }

  function roleFormatter(cell) {
    return cell.map(item => <div key={item.key}>{item.name}</div>);
  }
  return {
    columns: [
      {
        dataField: 'id',
        text: 'ID',
        sort: true,
        sortCaret: order => (
          <React.Fragment>
            {order ? order === 'asc' ? <Carret deg="-90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret>}
          </React.Fragment>
        ),
        editable: false,
      },
      {
        dataField: 'username',
        text: 'Логин',
        headerFormatter: formatter.placeholder,
        sort: true,
        sortCaret: order => (
          <React.Fragment>
            {order ? order === 'asc' ? <Carret deg="-90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret>}
          </React.Fragment>
        ),
        filter: textFilter({
          className: 'customPlaceholder',
          id: 'petuh',
          placeholder: 'Логин',
        }),
        editable: false,
      },
      {
        dataField: 'fullname',
        text: ' ',
        headerFormatter: formatter.placeholder,
        filter: textFilter({
          className: 'customPlaceholder',
          placeholder: 'ФИО',
        }),
        editable: false,
        sort: true,
        sortCaret: order => (
          <React.Fragment>
            {order ? order === 'asc' ? <Carret deg="-90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret>}
          </React.Fragment>
        ),
      },
      {
        dataField: 'roles',
        text: 'Роль',
        sort: true,
        sortCaret: order => (
          <React.Fragment>
            {order ? order === 'asc' ? <Carret deg="-90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret>}
          </React.Fragment>
        ),
        formatter: roleFormatter,
        editable: false,
        headerStyle: {
          width: 310,
        },
      },
      {
        dataField: 'createdAt',
        text: 'Создан',
        editable: false,
        formatter: formatter.dateFormat,
        sort: true,
        sortCaret: order => (
          <React.Fragment>
            {order ? order === 'asc' ? <Carret deg="-90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret>}
          </React.Fragment>
        ),
        headerStyle: {
          width: 110,
        },
      },
      {
        dataField: 'updatedAt',
        text: 'Изменен',
        editable: false,
        formatter: formatter.dateFormat,
        sort: true,
        sortCaret: order => (
          <React.Fragment>
            {order ? order === 'asc' ? <Carret deg="-90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret> : <Carret deg="90deg">&#8249;</Carret>}
          </React.Fragment>
        ),
        headerStyle: {
          width: 110,
        },
      },
      {
        dataField: 'Settings',
        text: '',
        isDummyField: true,
        headerStyle: {
          width: 173,
        },
        formatter: iconFormatter,
        formatExtraData: {},
        editable: false,
      },
    ],
  };
}
