import React from 'react';
import { MdExitToApp } from 'react-icons/md';
import {
  LogoutLink, UserName, UserInfo, UserRole, UserLogoutBox, UserNameBox,
} from '../../../styles/LoginStyles';
import { ReactComponent as UserIcon } from '../../../images/user.svg';

const Logout = ({ username, userRole }) => {
  const handleLogout = () => {
    window.localStorage.clear();
  };


  return (
    <React.Fragment>
      <UserInfo>
        <UserNameBox>
          <UserName>
            {username}
          </UserName>
          <UserRole>{userRole}</UserRole>
        </UserNameBox>
        <UserLogoutBox>
          <UserIcon />
          <LogoutLink to="/login" onClick={handleLogout}>
            Выйти
            <MdExitToApp style={{ fontSize: 16 }} />
          </LogoutLink>
        </UserLogoutBox>
      </UserInfo>
    </React.Fragment>
  );
};

export default React.memo(Logout);
