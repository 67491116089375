import styled from 'styled-components';
import c from './Colors';

export const TableBox = styled.div`
  .table {
    width: 100%;
    border-spacing: 0px;
  }
  .sr-only {
    display: none;
  }

  .table thead th{
    position: relative;
    padding: 12px;
    vertical-align: bottom;
    border-bottom: 2px solid #a4b7c1;
    text-align: left;
    font-size: 14px;
    outline: 0;
  }

  .table thead th input{
    border: 0;
    width: 100%;
  }

  .table tbody td {
    padding: 7px 12px;
    border-top: 1px solid #a4b7c1;
    background: #fcfcfc;
    font-size: 14px;
  }

  .react-bootstrap-table-pagination{
    display: flex;
    justify-content: space-between;
  }

  #pageDropDown {
    background: #cecece;
    border: 0;
    padding: 12px 24px;
    cursor: pointer;
    outline: 0;
  }

  .react-bs-table-sizePerPage-dropdown{
    position: relative;
  }

  .react-bs-table-sizePerPage-dropdown ul {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: -250%;
    width: 100%;
    background: #fff;
    border: 1px solid #cecece;
    transition: .1s;
  }

  .react-bs-table-sizePerPage-dropdown ul.show{
    visibility: visible;
    opacity: 1;
  }

  .react-bs-table-sizePerPage-dropdown ul li {
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .react-bs-table-sizePerPage-dropdown ul li a {
    color: #000000!important;
  }

  .pagination{
    display: flex;
  }

  .pagination li a{
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: ${c.main};
    background-color: #fff;
    border: 1px solid #a4b7c1;
  }

  .page-link{
    padding: 5px;
    color: ${c.main};
    text-decoration: none;
    border: 1px solid #000;
  }

  .page-item.active .page-link{
    color: ${c.white};
    text-decoration: none;
    background: ${c.main};
  }

  .table thead th .filterBoxTable{
    display: flex;
    align-items: center;
    div{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    label{
      width: 100%;
    }
    input {
      font-size: 18px;
      height: 24px;
      outline: 0;
      border-bottom: 2px solid transparent;
      &::-webkit-input-placeholder {
        transition: .3s;
      }
      &:focus::-webkit-input-placeholder { 
        opacity: 0; 
      }
      &:focus:-moz-placeholder { 
        opacity: 0;
      } 
      &:focus:-ms-input-placeholder { 
        opacity: 0; 
      }
      &:focus{
        border-bottom: 2px solid ${c.main};
      }
    }
    select {
      display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3e515b;
    background-clip: padding-box;
    border: 1px solid #c2cfd6;
    border-radius: 0;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  }

`;

export const PaginationBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
`;

export const TableItem = styled.div`
  display: flex;
  position: relative;
  input{
    width: 100%;
  }
`;

export const Carret = styled.div`
  position: absolute;
  right: 5%;
  top: 10%;
  padding: 5px;
  transform: ${props => `rotate(${props.deg})`};
  transition: .3s;
  font-size: 28px;
  color: ${c.main};
  cursor: pointer;
`;
