import React from 'react';
import { NavMenuBox } from '../../../../styles/NavMenu';
import NavMenuItems from './NavMenu';

const NavMenu = ({ permissions }) => (
  <NavMenuBox>
    <NavMenuItems permissions={permissions} />
  </NavMenuBox>
);

export default NavMenu;
