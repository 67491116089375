import React, { useState, useEffect } from 'react';
import useReactRouter from 'use-react-router';
import { FaAngleDown } from 'react-icons/fa';
import {
  NavLink, NavIcon, NavLinkBox, NavSubLink, NavSubLinkBox, NavSubBox, NavMenuBox,
} from '../../../../styles/NavMenu';

import navItemsList from './navItemsList';

const NavMenu = ({ permissions }) => {
  const [isHover, setIsHover] = useState(false);
  const [path, setPath] = useState('');
  const { location } = useReactRouter();

  const showMenu = (menu) => {
    setIsHover(menu);
  };

  useEffect(() => {
    setPath(location.pathname);
  }, [location.pathname]);

  const renderItems = () => navItemsList.map((item) => {
    let activeMenu = false;
    let subMenuArr = [];
    if (item.subItems.length) {
      subMenuArr = item.subItems.map((subItem) => {
        let activeLink = false;
        if (subItem.link === path) {
          activeMenu = true;
          activeLink = true;
        }
        return (
          <NavSubLinkBox key={subItem.link} activeLink={activeLink}>
            <NavSubLink to={subItem.link}>
              <NavIcon>{subItem.icon}</NavIcon>
              {subItem.text}
            </NavSubLink>
          </NavSubLinkBox>
        );
      });
    } else if (item.link === path) {
      activeMenu = true;
    }
    const kek = item.permissons.filter(itemm => permissions.indexOf(itemm) !== -1);
    if (kek.length) {
      return (
        <div
          key={item.text}
          onMouseEnter={() => showMenu(item.text)}
          onMouseLeave={() => showMenu(null)}
        >
          <NavLinkBox key={item.text} activeMenu={activeMenu} hover={isHover === item.text}>
            <NavLink to={item.link}>
              {item.text}
              {item.subItems.length ? <FaAngleDown /> : null}
            </NavLink>
          </NavLinkBox>
          <NavSubBox activeMenu={activeMenu} isHover={isHover === item.text}>
            {subMenuArr}
          </NavSubBox>
        </div>
      );
    }
    return null;
  });

  return (
    <NavMenuBox>
      {renderItems()}
    </NavMenuBox>
  );
};

export default NavMenu;
