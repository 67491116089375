import styled from 'styled-components';
import c from './Colors';
import Portal from '../views/Elements/Modal/PortalModal';

export const ModalWithTransitionStyles = styled(Portal)`
  &.modal-transition-enter {
    transform: translateX(-100%);
  }
  &.modal-transition-enter-active {
    transition: transform ${300}ms;
    transform: translateX(0);
  }
  &.modal-transition-exit {
    transform: translateX(0);
  }
  &.modal-transition-exit-active {
    transition: transform ${300}ms;
    transform: translateX(-100%);
  }
  div {
    max-width: ${props => props.maxWidth || '600px'}; 
  }
`;

export const ModalBackground = styled.div`
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  overflow-y: auto;
`;

export const PreloaderBackground = styled(ModalBackground)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalBox = styled.div`
  max-width: 600px;
  background: ${c.white};
  z-index: 999999;
  margin: 30px auto;
`;

export const ModalContentBox = styled.div`
  width: 100%;
  flex-direction: column;
  position: relative;
`;

export const ModalContentWrapper = styled.div`
  position: relative;
  margin: 20px auto;
  max-width: 600px;
`;

export const ModalContent = styled.div`
  position: relative;
  display: flex;
  background: #fff;
  flex-direction: column;
`;

export const ModalHeader = styled.div`
  position: relative;
  display: flex;
  background: ${c.main};
  font-size: 0.6875rem;
  line-height: 1.2;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: white;
  padding: 8px 20px;
`;

export const ModalTitle = styled.h2`
  width: 100%;
  margin: 0;
  font-weight: normal;
  font-size:18px;
  color: white;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  text-align: center;
`;

export const BtnClose = styled.div`
  position: absolute;
  top: -15px;
  right: -18px;
  background-image: ${props => (props.img ? `url(${props.img})` : 'none')};
  background-size: contain;
  width: 35px;
  height: 35px;
  cursor: pointer;
`;

export const ModalBody = styled.div`
  padding: 20px;
`;


export const Input = styled.input`
  border: 1px solid #c2cfd6;
  padding: 6px 12px;
  margin-bottom: 20px;
`;

export const Select = styled.select`
  
`;

export const BtnsBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const Btn = styled.button`
  background: ${props => (props.bg || '#64C270')};
  color: #ffffff;
  margin: ${props => (props.margin ? props.margin : 0)};
  border: 0;
  padding: 10px 16px;
`;

export const ErrorText = styled.p`
  text-align: center;
`;

export const CommentText = styled.p`
  margin: 0 0 20px;
  text-align: center;
`;

export const Comment = styled.textarea`
  width: 100%;
  resize: none;
  padding: 10px;
`;
