import styled from 'styled-components';
import { Link } from 'react-router-dom';
import c from './Colors';

export const LoginBox = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 40px;
  background-color: #E7EBF0;
  justify-content: center;
  z-index: 9;
`;

export const LogoutLink = styled(Link)`
  position: absolute;
  color: ${c.white};
  display: flex;
  width: 64px;
  align-items: center;
  font-size: 13px;
  justify-content: center;
  bottom: 0;
  svg{
    margin: 0!important;
    width: 16px!important;
  }
`;

LogoutLink.displayName = 'a';

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const LoginText = styled.p`
  font-size: 14px;
  color: ${c.main};
  margin-bottom: 25px;
`;

export const Btn = styled.button`
  background: ${props => c[props.bg]};
  color: ${c.white};
  border: 0;
  padding: 10px;
  font-size: 17px;
`;

export const UserInfo = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0 0 2px 0;
  svg{
    width: 25px;
    height: auto;
    fill: ${c.white}; 
    margin: 0 0 11px;
  }
`;

export const UserName = styled.p`
  display: flex;
  align-items: center;
  width: 100%;
  color: ${c.white};
  margin-bottom: 0px;
  font-size: 16px;
`;

export const UserRole = styled.p`
  font-size: 13px;
  text-align: right;
  position: absolute;
  bottom: 0px;
  right: 20px;
`;

export const UserLogoutBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 80px;
`;

export const UserNameBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content:center;  
  color: ${c.orng};
  padding: 0 20px 0 10px;
`;
