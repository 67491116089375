import styled from 'styled-components';
import c from './Colors';

export const Preloader = styled.div`
  
`;

export const PreloaderBox = styled.div`
  position: absolute;
  display: flex;  
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.4);
  color: ${c.white}
  height: ${props => (props.height ? props.height : '150px')}
`;
