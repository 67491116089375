import React, { useState, useEffect } from 'react';
import axios from 'axios';
import request from '../../../utils/request';
import { Title, ContentBox, PreloaderBox } from '../../../styles/Common';
import UsersTable from './UsersTable';
import { useToggle } from '../../../hooks/useToggle';
import NewUser from './NewUser/NewUser';

const Users = ({ preloader, showError, userRoles }) => {
  const initialData = {
    username: '',
    password: '',
    email: '',
    fullname: '',
    roles: [],
  };

  const [userList, setUserList] = useState({ data: [], isFetching: false });
  const [userData, setUserData] = useState(initialData);
  const [userUpdate, setUserUpdate] = useState(false);
  const [isOpen, toggleOpen] = useToggle(false);
  let isMount = true;

  const fetchUsers = async () => {
    if (isMount) setUserList({ data: [], isFetching: true });
    preloader();
    try {
      const res = await request('/api/users/', 'get');
      preloader();
      if (isMount) setUserList({ data: res.data.response, isFetching: false });
    } catch (e) {
      if (isMount) setUserList({ data: [], isFetching: false });
      preloader();
      showError('ошибка получения списка пользователей');
    }
  };

  useEffect(() => {
    fetchUsers();
    return () => {
      isMount = false;
    };
  }, []);

  const fetchExcel = () => {
    axios({
      method: 'get',
      url: '/api/users/excel',
    });
  };

  return (
    <React.Fragment>
      <Title>Пользователи</Title>
      <ContentBox>
        {userRoles.indexOf('userCreate') !== -1 && (
        <NewUser
          initialData={initialData}
          preloader={preloader}
          toggleOpen={toggleOpen}
          isOpen={isOpen}
          fetchUsers={fetchUsers}
          userData={userData}
          setUserData={setUserData}
          setUserUpdate={setUserUpdate}
          userUpdate={userUpdate}
          showError={showError}
        />
        )}
        {/* <button onClick={fetchExcel}>lol</button> */}
        <PreloaderBox>
          <UsersTable
            userList={userList}
            fetchUsers={fetchUsers}
            preloader={preloader}
            setUserUpdate={setUserUpdate}
            setUserData={setUserData}
            userData={userData}
            toggleOpen={toggleOpen}
            showError={showError}
            userRoles={userRoles}
          />
        </PreloaderBox>
      </ContentBox>
    </React.Fragment>
  );
};
export default Users;
