import React from 'react';
import {
  Input, Select, InputBox, Label, CheckBox, InputCheck, Check, LabelText, CheckContainer,
} from '../../../styles/Common';

const InputField = ({ item, onChange, edit }) => {
  const {
    value, type, name, options, label,
  } = item;
  const renderField = () => {
    switch (type) {
      case 'select':
        return (
          <Select onChange={onChange} value={value} name={name} multiple={false}>
            <option value="">Не выбрано</option>
            {options}
          </Select>
        );
      case 'checkbox':
        return (
          <CheckContainer>
            <LabelText>{label}</LabelText>
            <CheckBox>
              <InputCheck type="checkbox" value={value} onChange={onChange} name={name} checked={value} />
              <Check />
            </CheckBox>
          </CheckContainer>
        );
      default:
        return <Input type="text" secure={type} value={value} onChange={onChange} name={name} readOnly={edit === false} />;
    }
  };
  return (
    <InputBox>
      {type !== 'checkbox' && <Label>{label}</Label>}
      {renderField()}
    </InputBox>
  );
};

export default InputField;
