import React from 'react';
import {
  Input, FullnameBox, DelVisitor,
} from '../../../../../../styles/requestForm';

const VisitorInput = ({
  deleteVisitor, visitor, handleInput, orderView,
}) => (
  <FullnameBox mw="30%">
    <Input onChange={e => handleInput(e, visitor.id)} name="surname" value={visitor.surname} required type="text" placeholder="Фамилия" readOnly={orderView} />
    <Input onChange={e => handleInput(e, visitor.id)} name="name" value={visitor.name} required type="text" placeholder="Имя" readOnly={orderView} />
    <Input onChange={e => handleInput(e, visitor.id)} name="patronymic" value={visitor.patronymic || ''} type="text" placeholder="Отчество" readOnly={orderView} />
    {!orderView && <DelVisitor onClick={() => deleteVisitor(visitor.id)}>X</DelVisitor>}
  </FullnameBox>
);

export default VisitorInput;
